import {inject, LogManager} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {Wizard} from "../../widgets/wizard/wizard.js";

const logger = LogManager.getLogger('action-handler');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    Wizard
)
export class WizardActionHandler extends ActionHandlerInterface
{
    constructor(
        wizard
    ) {
        super();

        this.wizard = wizard;
    }

    getActionType()
    {
        return 'wizard';
    }

    getReturnContext(config, context)
    {
        return Object.assign(
            {
                steps: config.steps,
                successMessage: config.successMessage,
                updateModelIds: config.updateModelIds,
                bindValues: config.bindValues,
                previewGeneratorId: config.previewGeneratorId,
                previewPreset: config.previewPreset,
                previewPrevious: config.previewPrevious,
                title: config.title,
                label: config.label,
                formId: config.formId,
                additionalData: config.additionalData,
                cssClass: config.cssClass
            },
            context
        );
    }

    getAction(returnContext)
    {
        return  async () => {
            logger.debug('Performing `wizard` action with context ', returnContext);
             await this.wizard.open(returnContext);
        };
    }
}
