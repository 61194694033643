import {customElement, inject} from "aurelia-framework";
import {WorkflowService} from "../../workflow/workflow-service";
import "./balance-list.less";

@inject(WorkflowService)
@customElement('sio-accounting-balance-list')
export class BalanceList {
    /** @type {WorkflowService} */
    workflowService;
    /** @type {Form} */
    form;

    pdfConfig = {
        label: 'action.pdf-download',
        buttonClass: 'btn btn-primary',
        disabled: '',
    }

    excelConfig = {
        label: 'action.excel-download',
        buttonClass: 'btn btn-primary',
        disabled: '',
    }

    showConfig = {
        label: 'action.show',
        buttonClass: 'btn btn-primary',
        disabled: '',
    }

    constructor(workflowService) {
        this.workflowService = workflowService;
        this.submit = this.submit.bind(this);
    }

    async submit(type) {
        this.pdfConfig.disabled = 'disabled'
        this.excelConfig.disabled = 'disabled'
        this.showConfig.disabled = 'disabled'
        this.data = null;
        this.sumArr = {
            "currency": "EUR",
            "values": {
                "transfer": 0,
                "monthDr": 0,
                "monthCr": 0,
                "yearDr": 0,
                "yearCr": 0,
                "balance": 0
            }
        };

        const formData = this.form.formService.getValue();
        const data = await this.workflowService.trigger(
            `accounting/generate-balance-list${type ? "-" + type : ""}`,
            null,
            {
                ...formData,
                organization: {id: formData.organization.id, modelId: formData.organization.modelId},
                period: {id: formData.period.id, modelId: formData.period.modelId},
            }
        ).then();

        this.data = data[0].list
        this.pdfConfig.disabled = ''
        this.excelConfig.disabled = ''
        this.showConfig.disabled = ''

        if (data[0].url) {
            window.location.href = data[0].url;
        }

        [].concat(...Object.values(this.data).map(value => Object.entries(value.sum))).forEach(
            data => Object.entries(data[1]).forEach(([key, value]) => this.sumArr.values[key] += value, this)
        )
    }
}
